import React, { useEffect } from 'react';
import { data } from './data';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css';

const { project } = data;

function Projects() {
  useEffect(() => {
    AOS.init({ duration: 1000 }); 
  }, []);

  const handleLinkClick = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <div>
        <h2
          id="solutiontitle"
          className="title text-[32px] text-center leading-[1.2] font-normal pb-[4rem]"
          data-aos="fade-up"
        >
          OUR Projects
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:items-center md:mx-10 items-center relative bg-white">
          {project.map((li, key) => (
            <div
              key={key}
              className="mx-4 text-left border-none rounded-[15px] h-auto transform duration-400 ease-in bg-white shadow-lg hover:shadow-2xl cursor-pointer mb-2 mt-2"
              data-aos="fade-up"
              data-aos-delay={key * 10} 
            >
              <img
                src={li.img}
                alt={li.altdata}
                className="border-none rounded-t-[15px] md:min-h-[200px] md:max-h-[200px]"
                data-aos="zoom-in"
              />
              <div className="px-4 pb-4">
                <h5
                  className="font-semibold text-[24px] mt-10 mb-[1.5rem] leading-[1.2] text-[#1B1C19] capitalize"
                  data-aos="fade-up"
                >
                  {li.title}
                </h5>
                <p
                  className="font-light text-[16px] mb-2 text-[#1B1C19]"
                  data-aos="fade-up"
                >
                  {li.description}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center my-16">
          <Link to="/portfolio">
            <button
              className="relative bg-[#fc4442] text-white hover:text-[#fc4442] text-[18px] font-semibold border-2 border-[#fc4442] px-[25px] py-[10px] transition-all duration-500 ease-linear rounded-[50px] mb-[0%] hover:bg-transparent"
              onClick={handleLinkClick}
              data-aos="fade-up"
              data-aos-delay="300"
            >      Explore More Projects <span className='pl-1'><i class="fa fa-arrow-right"></i></span> </button>
          </Link>
        </div>

      </div>

    </>
  )
}

export default Projects;
