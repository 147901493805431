// import {Profiler, useState} from 'react'
import './index.css'
import React from 'react'
import { Routes, Route } from "react-router-dom";
import Navbar from './MainComponents/Navbar/Navbar'
import Footer from './MainComponents/Footer/Footer'
import MainHero from './Pages/Home/MainHero'
import Portfolio from './Pages/Portfolio/portfolio'
import Team from './Pages/Teams/Team'
import About from './Pages/About/About'
import Contact from './Pages/Contact/Contact'
import ScrollToTop from './MainComponents/scroll-to-top/ScrollToTop';
function App() {
  return (
    <>
      <div className="generalbody">
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route exact path="/" element={<MainHero />} />
          <Route exact path="/portfolio" element={<Portfolio />} />
          <Route exact path="/team" element={<Team />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </>
  );
}
export default App;

