import project1 from '../alphaprojects/abaanmart.png';
import project2 from '../alphaprojects/bugtracker.png';
import project5 from '../alphaprojects/mandimaster.png';
import project8 from '../alphaprojects/tufailhashmi.png';
import project9 from '../alphaprojects/zyndexllc.png';
import project10 from '../alphaprojects/mydriverental.png';
import project11 from '../alphaprojects/cobracraze.png';
import project12 from '../alphaprojects/mywisdomhub.png';
import project13 from '../alphaprojects/888hml.png';
import project14 from '../alphaprojects/buildmate.png';
import project15 from '../alphaprojects/chitralgreenguesthouse.png';

export const Data = [
    {
        no: 15,
        img: project15,
        category: 'Web Apps',
        title: 'Chitral Green Guest House',
        description:"Enjoy a seamless booking experience at our guest house, tailored for your comfort and convenience from reservation to checkout.",
        btntitle: 'Explore',
        altdata: '15 Project',
        link:'https://chitralgreenguesthouse.com/',
    },
    {
        no: 8,
        img: project8,
        category: 'Web Apps',
        title: 'Tufail Hashmi Portfolio',
        description:"Discover Tufail Hashmi Portfolio: Showcasing Tech Excellence.Explore sleek websites, user-friendly apps, and expert designs. See innovation come to life, project by project.",
        btntitle: 'Explore',
        altdata: '8 Project',
        link:'https://tufailhashmi.com/',
    },
    {
        no: 14,
        img: project14,
        category: 'Web Apps',
        title: 'BuildMate Startup',
        description:"Experience AI-powered construction estimation with BuildMate. Get accurate project insights and streamline workflows for efficient planning...",
        btntitle: 'Explore',
        altdata: '14 Project',
        link:'https://buildmate-live.netlify.app/',
    },
    {
        no: 2,
        img: project2,
        category: 'Web Apps',
        title: 'Bug Tracker Product',
        description:"Streamline software development with efficient bug tracking. Detect, report, and resolve issues swiftly for enhanced project delivery and client satisfaction.",
        btntitle: 'Our Process',
        altdata: '2 Project',
        link:'https://muhammadmobeen.com/bug-tracker',
    },
    {
        no: 13,
        img: project13,
        category: 'Web Apps',
        title: 'Hml Accident Services',
        description:"HML Accident Services is a unique firm that specializes in getting large settlements as well as verdicts. Unlike other firms, we give you the individualized attention...",
        btntitle: 'Explore',
        altdata: '13 Project',
        link:'https://888hml.com/',
    },
    {
        no: 10,
        img: project10,
        category: 'Web Apps',
        title: 'Rental Car Booking Website',
        description:"We're thrilled to provide a customized car rental solution. Our seamless database management is tailored exclusively for our valued client's convenience.",
        btntitle: 'View Details',
        altdata: '10 Project',
        link:'https://mydriverentals.com/',
    },
    {
        no: 12,
        img: project12,
        category: 'Web Apps',
        title: 'MY Wisdom Hub',
        description:"Dive into a world of insightful articles and thought-provoking discussions at MY Wisdom Hub. Explore a diverse range of topics, from technology trends to personal development tips.",
        btntitle: 'Explore',
        altdata: '12 Project',
        link:'https://mywisdomhub.com/',
    },
    {
        no: 11,
        img: project11,
        category: 'Web Apps',
        title: 'CobraCraze Ecommerce',
        description:"Unleash the power of Cobracraze: Our premier ecommerce solution, customer satisfaction, effortless shopping experiences and unparalleled profitability",
        btntitle: 'View Details',
        altdata: '11 Project',
        link:'https://cobracraze.com/',
    },
    {
        no: 5,
        img: project5,
        category: 'Web Apps',
        title: 'MandiMaster Bridging Farmers and Markets',
        description:'MandiMaster streamlines agricultural trade by bridging farmers directly to markets, fostering efficiency and empowering communities.',
        btntitle: 'Learn More',
        altdata: '5 Project',
        link:'https://markhorverse.pythonanywhere.com/',
    },
    {
        no: 1,
        img: project1,
        category: 'Web Apps',
        title: 'AbaanMart Ecommerce Solution',
        description:"Unlock AbaanMart Ecommerce Mastery. From inventory to checkout, Simplify management, delight customers, and drive profitability.",
        btntitle: 'Read Up',
        altdata: '1 Project',
        link:'https://abaanmart.com/',
    },
    {
        no: 9,
        img: project9,
        category: 'Web Apps',
        title: 'Zyndex Bussiness Solution',
        description:"Unlock Zyndex's Ecommerce Mastery. From inventory to checkout, harness powerful tools for efficient online ventures. Simplify management, delight customers, and drive profitability.",
        btntitle: 'View Details',
        altdata: '9 Project',
        link:'https://zyndexllc.com/',
    },
];
