import ahmed from '../alphas/ahmed.jpg'
import zain from '../alphas/zain.jpg'
import jawad from '../alphas/jawad.jpeg'
import ibrahim from '../alphas/ibrahim.jpg'
import awais from '../alphas/awais.jpg'
import fasih from '../alphas/fasih.jpeg'
import hamza from '../alphas/hamza.jpg'
import daniyal from '../alphas/daniyal.jpg'
import ansar from '../alphas/ansar.jpeg'
import abdullah from '../alphas/abdullah.jpg'
import narinder from '../alphas/narinder.jpeg'
import nouman from '../alphas/noman.jpg'

export const Data = [
    {
        id:1,
        name: "Ahmed Rohail Awan",
        role:"CEO & Co-Founder",
        image:ahmed

    },
    {
        id:2,
        name: "Zain ul Abaideen",
        role:"Web Developer",
        image:zain

    },
    {
        id:3,
        name: "Jawad Ur Rehman",
        role:"UI/UX Designer",
        image:jawad

    },
    {
        id:4,
        name: "Awais Afzal",
        role:"MERN Stack Developer",
        image:awais
    },
    {
        id:5,
        name: "Hamza Farooq",
        role:"Digital Marketing",
        image:hamza
    },
    {
        id:6,
        name: "Daniyal Waheed",
        role:"AI/ML Engineer",
        image:daniyal
    },
    {
        id:7,
        name: "Ansar Hayat",
        role:"React developer",
        image:ansar
    },
    {
        id:8,
        name: "Muhammad Ibrahim",
        role:"React | Next JS Developer",
        image:ibrahim
    },
    {
        id:9,
        name: "Fasih Muhammad Virk",
        role:"Full Stack Developer",
        image:fasih
    },
    {
        id:10,
        name: "Muhammad Abduallah",
        role:"Angular Developer",
        image:abdullah
    },
    {
        id:11,
        name: "Narinder Kumar",
        role:"Php & Larael Developer",
        image:narinder
    },
    {
        id:12,
        name: "Nouman Khan",
        role:"Wordpress developer",
        image:nouman
    }
];